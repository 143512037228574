import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SubHeader from "../../components/subHeader/SubHeader";

interface Event {
  id: number;
  title: string;
  date: string;
  time: string;
  location?: string;
  type: string;
  image: string;
}

const Events: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const location = useLocation();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch('http://localhost:1337/api/events/scrape');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setEvents(data.data);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const locationFilter = searchParams.get('location')?.toLowerCase();
    const dateFilter = searchParams.get('date');
    const typeFilter = searchParams.get('type')?.toLowerCase();

    const filtered = events.filter(event => {
      const matchesLocation = locationFilter
          ? event.location?.toLowerCase().includes(locationFilter)
          : true;
      const matchesDate = dateFilter
          ? event.date === dateFilter
          : true;
      const matchesType = typeFilter
          ? event.type.toLowerCase() === typeFilter
          : true;

      return matchesLocation && matchesDate && matchesType;
    });

    setFilteredEvents(filtered);
  }, [location.search, events]);

  if (loading) {
    return <p className="text-center text-lg text-gray-600">Loading events...</p>;
  }

  if (error) {
    return <p className="text-center text-lg text-red-600">Error loading events: {error.message}</p>;
  }

  return (
      <div className="px-4 sm:px-6 lg:px-8">
        <SubHeader/>
        <div className="container mx-auto py-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredEvents.map((event) => (
              <Link to={`/events/${event.id}`} key={event.id} className="block">
                <div className="bg-white shadow-2xl rounded-2xl overflow-hidden h-auto">
                  <div className="relative bg-white shadow-2xl rounded-2xl overflow-hidden">
                    <img src={event.image} alt={event.title} className="w-full h-48 object-cover sm:h-56 md:h-64" />
                    <div className="absolute bottom-2 right-2 bg-white text-gray-800 p-2 rounded-full shadow-md">
                      <span className="text-sm font-medium">{event.type}</span>
                    </div>
                  </div>
                  <div className="p-4 sm:p-6">
                    <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-3.5">{event.title}</h3>
                    <div className="text-gray-600 mb-4">
                      <div className="flex items-center text-gray-600 mb-2">
                        <span className="material-icons mr-2">📍</span>
                        <span className="text-sm sm:text-base">{event.location || 'Location not available'}</span>
                      </div>
                      <div className="flex items-center mb-2">
                        <span className="material-icons mr-2"> 📆 </span>
                        <span className="text-sm sm:text-base">{event.date}</span>
                      </div>
                      <div className="flex items-center mb-2">
                        <span className="material-icons mr-2"> ⏰</span>
                        <span className="text-sm sm:text-base">{event.time}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
          ))}
        </div>
      </div>
  );
};

export default Events;
