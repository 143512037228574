import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/header/Header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Events from './containers/EventsList/EventsList';
import EventPage from './containers/EventPage/EventPage';
import EventListByType from './containers/EventListByType/EventListByType';
import SubHeader from "./components/subHeader/SubHeader";

function App() {
  
    return (
      <Router>
        <div>
            <Header/>
          <Routes>
            <Route path="/events" element={<Events />} />
            <Route path="/events/:id" element={<EventPage/>} />
            <Route path="/events/type/:type" element={<EventListByType/>} />
              <Route path="/search" element={<Events />} />


          </Routes>
        </div>
      </Router>
    );
  };

export default App;
