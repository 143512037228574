import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png'; // Adjust the path as needed
import germanFlag from '../../assets/german.png';
import dutchFlag from '../../assets/dutch.jpg';
import englishFlag from '../../assets/english.png';

const Header: React.FC = () => {
  const [language, setLanguage] = useState('de'); // Default to German
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLanguageChange = (lang: string) => {
    setLanguage(lang);
    setDropdownOpen(false); // Close dropdown after selection
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
      <nav className="bg-white p-4 shadow">
        <div className="container mx-auto flex justify-between items-center">
          {/* Logo */}
          <Link to="/events">
            <img src={logo} alt="Event App Logo" className="h-12 w-auto" />
          </Link>

          {/* Right side options */}
          <div className="flex items-center space-x-4">
            <button className="flex items-center bg-white border border-gray-300 rounded px-3 py-1 text-sm font-bold text-gray-700 hover:bg-gray-100">
              <svg
                  className="w-4 h-4 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 4v16m8-8H4"
                ></path>
              </svg>
              Event erstellen
            </button>
            <div className="relative">
              <button
                  className="flex items-center text-gray-700 focus:outline-none"
                  onClick={toggleDropdown}
              >
                <img
                    src={
                      language === 'de'
                          ? germanFlag
                          : language === 'nl'
                              ? dutchFlag
                              : englishFlag
                    }
                    alt="Selected Language Flag"
                    className="h-4 w-6 mr-2"
                />
                <span className="font-bold">
                {language === 'de' ? 'Deutsch' : language === 'nl' ? 'Dutch' : 'English'}
              </span>
              </button>
              {dropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg">
                    <button
                        className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => handleLanguageChange('de')}
                    >
                  <span className="flex items-center">
                    <img src={germanFlag} alt="German Flag" className="h-4 w-6 mr-2" /> Deutsch
                  </span>
                    </button>
                    <button
                        className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => handleLanguageChange('nl')}
                    >
                  <span className="flex items-center">
                    <img src={dutchFlag} alt="Dutch Flag" className="h-4 w-6 mr-2" /> Dutch
                  </span>
                    </button>
                    <button
                        className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => handleLanguageChange('en')}
                    >
                  <span className="flex items-center">
                    <img src={englishFlag} alt="English Flag" className="h-4 w-6 mr-2" /> English
                  </span>
                    </button>
                  </div>
              )}
            </div>
          </div>
        </div>
      </nav>
  );
};

export default Header;
