import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type Event = {
  id: string;
  title: string;
  description: string;
  date: string;
  time: string;
  location: string;
  type: string;
  image: string;
  accessibility: string[];
  price: string;

};

type Params = {
  id: string;
};

const EventListPage: React.FC = () => {
  const { id } = useParams<Params>();
  const [event, setEvent] = useState<Event | null>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await fetch(`http://localhost:1337/api/events/${id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setEvent(data.data as Event);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [id]);

  if (loading) {
    return <p className="text-center text-lg text-gray-600">Loading event...</p>;
  }

  if (error) {
    return <p className="text-center text-lg text-red-600">Error loading event: {error}</p>;
  }

  return (
      <div className="container mx-auto py-10">
        {event && (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="relative bg-white shadow-2xl rounded-2xl overflow-hidden p-4">
                <img src={event.image} alt={event.title} className="w-full   object-cover sm:h-56 md:h-64 "/>
                <div className="absolute bottom-2 right-2 bg-white text-gray-800 p-2 rounded-full shadow-md">
                  <span className="text-sm font-medium">{event.type}</span>
                </div>
              </div>
              <div className="p-6">
                <h3 className="text-xl font-bold mb-2">{event.title}</h3>
                <p className="text-gray-700 mb-4">{event.description || 'No additional information available'}</p>
                <div className="text-gray-600 mb-4">
                  <div className="flex items-center mb-2">
                    <span className="mr-2">📅</span>
                    <span>{event.date}</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="mr-2">⏰</span>
                    <span>{event.time}</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="mr-2">📍</span>
                    <span>{event.location || 'Location not available'}</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="mr-2">💶</span>
                    <span>{event.price}</span>
                  </div>
                </div>
              </div>
            </div>
        )}
      </div>
  );
};

export default EventListPage;
