import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { IoMdPin } from 'react-icons/io';
import { FiCalendar } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import sportIcon from '../../assets/soccerball.jpg';
import musicIcon from '../../assets/music.webp';
import foodIcon from '../../assets/burger.jpeg';
import travelIcon from '../../assets/beach.png';
import leisureIcon from '../../assets/controller.jpg';
import learnIcon from '../../assets/study.png';
import cultureIcon from '../../assets/culture.png';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

const SubHeader: React.FC = () => {
    const [location, setLocation] = useState<string>('');
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const navigate = useNavigate();

    const handleDateChange = (date: Date | null) => {
        setStartDate(date);
    };

    const constructSearchParams = (category: string = '') => {
        const searchParams = new URLSearchParams();

        if (location) {
            searchParams.append('location', location);
        }

        if (startDate) {
            searchParams.append('date', startDate.toISOString().split('T')[0]);
        }

        if (category) {
            searchParams.append('type', category);
        } else if (selectedCategory) {
            searchParams.append('type', selectedCategory);
        }

        return searchParams;
    };

    const handleSearch = () => {
        const searchParams = constructSearchParams();
        navigate(`/search?${searchParams.toString()}`);
    };

    const handleCategoryClick = (category: string) => {
        setSelectedCategory(category);
        setLocation('');  // Reset location filter
        setStartDate(null);  // Reset date filter

        const searchParams = new URLSearchParams();
        searchParams.append('type', category);  // Only include the new category

        navigate(`/search?${searchParams.toString()}`);
    };
    const clearFilters = () => {
        setLocation('');
        setStartDate(null);
        setSelectedCategory('');
        navigate('/search');  // Navigate to the base search URL without any filters
    };


    return (
        <div className="bg-white shadow-sm py-4">
            <div className="container mx-auto flex flex-col items-center space-y-4 px-4">
                {/* Search Section */}
                <div
                    className="flex flex-col sm:flex-row items-center w-full max-w-2xl space-y-2 sm:space-y-0 sm:space-x-2 bg-gray-100 p-4 rounded-lg">
                    <div className="flex items-center space-x-2 bg-white p-2 rounded-lg w-full sm:flex-1">
                        <IoMdPin className="text-red-500"/>
                        <input
                            type="text"
                            placeholder="Location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            className="w-full bg-transparent border-none outline-none text-gray-700"
                        />
                    </div>
                    <div className="flex items-center space-x-2 bg-white p-2 rounded-lg w-full sm:flex-1">
                        <FiCalendar className="text-gray-500"/>
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            className="w-full bg-transparent border-none font-medium outline-none text-gray-700"
                            placeholderText="Select Date"
                        />
                    </div>
                    <button
                        className="bg-blue-500 text-white p-2 rounded-lg flex items-center w-full sm:w-auto h-15 justify-center"
                        onClick={handleSearch}
                    >
                        <FaSearch/>
                        <span className="ml-2">Search</span>
                    </button>
                </div>

                {/* Category Buttons */}
                <div className="flex flex-wrap justify-center space-x-2 sm:space-x-4 overflow-x-auto">
                    <button
                        onClick={() => handleCategoryClick('sport-fitness')}
                        className="flex flex-col items-center bg-white p-2 rounded-lg shadow-2xl hover:shadow-lg transition-shadow w-24 sm:w-32"
                    >
                        <img src={sportIcon} alt="Sport & Fitness" className="h-8 w-8 mb-1"/>
                        <span className="text-sm font-medium text-gray-700 text-center">Sport & Fitness</span>
                    </button>
                    <button
                        onClick={() => handleCategoryClick('music-dance')}
                        className="flex flex-col items-center bg-white p-2 rounded-lg shadow-2xl hover:shadow-lg transition-shadow w-24 sm:w-32"
                    >
                        <img src={musicIcon} alt="Musik & Tanzen" className="h-8 w-8 mb-1"/>
                        <span className="text-sm font-medium text-gray-700 text-center">Music</span>
                    </button>
                    <button
                        onClick={() => handleCategoryClick('food-drink')}
                        className="flex flex-col items-center bg-white p-2 rounded-lg shadow-2xl hover:shadow-lg transition-shadow w-24 sm:w-32"
                    >
                        <img src={foodIcon} alt="Essen & Trinken" className="h-8 w-8 mb-1"/>
                        <span className="text-sm font-medium text-gray-700 text-center">Food</span>
                    </button>
                    <button
                        onClick={() => handleCategoryClick('travel')}
                        className="flex flex-col items-center bg-white p-2 rounded-lg shadow-2xl hover:shadow-lg transition-shadow w-24 sm:w-32"
                    >
                        <img src={travelIcon} alt="Reisen" className="h-8 w-8 mb-1"/>
                        <span className="text-sm font-medium text-gray-700 text-center">Leisure</span>
                    </button>
                    <button
                        onClick={() => handleCategoryClick('leisure')}
                        className="flex flex-col items-center bg-white p-2 rounded-lg shadow-2xl hover:shadow-lg transition-shadow w-24 sm:w-32"
                    >
                        <img src={leisureIcon} alt="Freizeit" className="h-8 w-8 mb-1"/>
                        <span className="text-sm font-medium text-gray-700 text-center">Games</span>
                    </button>
                    <button
                        onClick={() => handleCategoryClick('learn')}
                        className="flex flex-col items-center bg-white p-2 rounded-lg shadow-2xl hover:shadow-lg transition-shadow w-24 sm:w-32"
                    >
                        <img src={learnIcon} alt="Lernen" className="h-8 w-8 mb-1"/>
                        <span className="text-sm font-medium text-gray-700 text-center">Learning</span>
                    </button>
                    <button
                        onClick={() => handleCategoryClick('culture')}
                        className="flex flex-col items-center bg-white p-2 rounded-lg shadow-2xl hover:shadow-lg transition-shadow w-24 sm:w-32"
                    >
                        <img src={cultureIcon} alt="Kunst & Kultur" className="h-8 w-8 mb-1"/>
                        <span className="text-sm font-medium text-gray-700 text-center">Culture</span>
                    </button>
                </div>
            </div>
            <button
                className="bg-blue-400 text-white p-2 rounded-lg flex items-center w-full sm:w-auto h-15 justify-center mt-3.5"
                onClick={clearFilters}
            >
                Clear Filters
            </button>
        </div>
    );
};

export default SubHeader;
