import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';


type Event = {
  id: string;
  title: string;
  moreInfo: string;
  date: string;
  time: string;
  location: string;
  type: string;
  image: string;
};

type Params = {
  type: string;
};

const EventListByType: React.FC = () => {
  const { type } = useParams<Params>();
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEventsByType = async () => {
      try {
        const response = await fetch(`http://localhost:1337/api/events/type/${type}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setEvents(data.data || []);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    if (type) {
      fetchEventsByType();
    }
  }, [type]);

  if (loading) {
    return <p className="text-center text-lg text-gray-600">Loading events...</p>;
  }

  if (error) {
    return <p className="text-center text-lg text-red-600">Error loading events: {error}</p>;
  }

  return (
    <div>
     
      <div className="container mx-auto py-10">
        {events.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {events.map((event) => (
              <Link to={`/events/${event.id}`} key={event.id} className="block">
                <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                  <img src={event.image} alt={event.title} className="w-full h-48 object-cover" />
                  <div className="p-6">
                    <h3 className="text-xl font-bold mb-2">{event.title}</h3>
                    <p className="text-gray-700 mb-4">{event.moreInfo}</p>
                    <div className="text-gray-600 mb-4">
                      <div className="flex items-center mb-2">
                        <span className="material-icons mr-2">Date:</span>
                        <span>{event.date}</span>
                      </div>
                      <div className="flex items-center">
                        <span className="material-icons mr-2">Time:</span>
                        <span>{event.time}</span>
                      </div>
                    </div>
                    <div className="flex items-center text-gray-600 mb-4">
                      <span className="material-icons mr-2">Location:</span>
                      <span>{event.location || 'Location not available'}</span>
                    </div>
                    <div className="text-gray-600">
                      <span className="material-icons mr-2">Type:</span>
                      <span>{event.type}</span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <p className="text-center text-lg text-gray-600 font-bold">No events found for type "{type}".</p>
        )}
      </div>
    </div>
  );
};

export default EventListByType;
